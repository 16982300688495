import type { UseDisclosureProps } from '@heroui/react';

import { useTranslations } from 'next-intl';

import type { dice } from '@org/query';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

interface DeleteClientModalContentProps {
  disclosureState: UseDisclosureProps;
  deleteClient: (clientId: string) => Promise<void>;
  selectedClient: dice.ClientDTO;
}

export const DeleteClientModalContent = ({
  disclosureState,
  selectedClient,
  deleteClient,
}: DeleteClientModalContentProps) => {
  const t = useTranslations();
  const { clientName = '', id } = selectedClient;
  const { onClose } = disclosureState;

  return (
    <>
      <ModalHeader>
        {t('common.contextMenu.modalDelete.title', {
          name: clientName,
          object: '',
        })}
      </ModalHeader>
      <ModalBody>{t('common.contextMenu.modalDelete.label')}</ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          onPress={onClose}
          size="md"
          variant="secondary"
        >
          {t('common.btnCancel')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={!clientName}
          onPress={async () => {
            try {
              await deleteClient(id!);
              onClose?.();
            } catch {
              showNotification({
                status: 'warning',
                message: t('main.clients.clientsOverviewPanel.alerts.warning.general'),
              });
            }
          }}
          size="md"
          variant="primary"
        >
          {t('common.contextMenu.modalDelete.btnDelete')}
        </Button>
      </ModalFooter>
    </>
  );
};
