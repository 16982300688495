import type React from 'react';
import type { ChangeEvent } from 'react';
import { useState } from 'react';

import { useDisclosure } from '@heroui/react';
import { useForm } from 'react-hook-form';

import type { dice } from '@org/query';
import { useUserPermission } from '@org/features';
import { useDebounceFn } from '@org/hooks';
import { aggregated } from '@org/query';

export interface UseControllerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LinkComponent: React.ComponentType<any>;
  getLinkProps: (clientName: string) => Record<string, unknown>;
}

export const useController = ({ LinkComponent, getLinkProps }: UseControllerProps) => {
  const { createClient, updateClient, deleteClient } = aggregated.useClient({});

  const { clientsAndRoles } = aggregated.useClients();
  const { user } = useUserPermission({ clientId: undefined });

  const disclosureState = useDisclosure();

  const { control } = useForm({});

  const [searchedItems, setSearchedItems] = useState<dice.ClientRoleDTO[]>([]);

  const handleSearch = useDebounceFn(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (!value) {
        setSearchedItems(clientsAndRoles ?? []);
        return;
      }

      const foundItems = clientsAndRoles?.filter((item) => {
        const clientName = item.client?.clientName ?? '';
        const found = clientName.search(new RegExp(value, 'i'));
        if (found !== -1) {
          return item;
        }
      });
      setSearchedItems(foundItems ?? []);
    },
    { wait: 200 },
  );

  const clientsToBeShown = searchedItems.length > 0 ? searchedItems : clientsAndRoles;

  const sortedClients =
    clientsToBeShown?.sort((a, b) => {
      if (a.client?.clientName && b.client?.clientName) {
        return a.client.clientName.localeCompare(b.client.clientName);
      }
      return 0;
    }) ?? [];

  return {
    LinkComponent,
    control,
    createClient,
    deleteClient,
    disclosureState,
    getLinkProps,
    handleSearch,
    sortedClients,
    updateClient,
    user,
  };
};

export type ControllerType = ReturnType<typeof useController>;
