import { useCallback } from 'react';

import { skipToken } from '@tanstack/react-query';

import type { ClientDTO, GetClientPathParams } from '../dice';
import { useDeleteClient, useGetClient, useSaveClients } from '../dice';

interface UseClientsProps {
  clientName?: GetClientPathParams['name'];
}

export const useClient = (props: UseClientsProps = {}) => {
  const { clientName } = props;

  const { data: client, isPending: isPendingClient } = useGetClient(
    clientName
      ? {
          pathParams: {
            name: clientName,
          },
        }
      : skipToken,
  );

  const { mutateAsync: deleteClientMutation } = useDeleteClient({});

  const { mutateAsync: updateOrCreateClient } = useSaveClients({});

  const createClient = useCallback(
    async (name: string) =>
      await updateOrCreateClient({
        body: [
          {
            clientName: name,
          },
        ],
      }),
    [updateOrCreateClient],
  );

  const deleteClient = useCallback(
    async (id: string) =>
      await deleteClientMutation({
        pathParams: {
          clientId: id,
        },
      }),
    [deleteClientMutation],
  );

  const updateClient = useCallback(
    async <TData = ClientDTO>(updatedClient: TData) =>
      await updateOrCreateClient({
        body: [updatedClient as ClientDTO],
      }),
    [updateOrCreateClient],
  );

  return {
    client,
    createClient,
    deleteClient,
    isLoading: isPendingClient,
    updateClient,
  };
};
