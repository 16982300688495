import { useCallback } from 'react';

import { skipToken } from '@tanstack/react-query';

import type { ShareClientNewRequestBody } from '../dice';
import { useGetClientUsers, useGetUserRoles, useShareClientNew } from '../dice';

interface UseClientShareProps {
  clientId?: string;
}

export const useClientShare = ({ clientId }: UseClientShareProps) => {
  const { data: roles, isPending: isPendingRoles } = useGetUserRoles({});
  const { data: clientUsers, isPending: isPendingUsers } = useGetClientUsers(
    clientId
      ? {
          pathParams: {
            clientId,
          },
        }
      : skipToken,
  );

  const { mutateAsync: shareClientAsync } = useShareClientNew({});

  const shareClient = useCallback(
    async ({ toClientId, toUsers }: { toClientId: string; toUsers: ShareClientNewRequestBody }) =>
      await shareClientAsync({
        body: toUsers,
        pathParams: {
          clientId: toClientId,
        },
      }),
    [shareClientAsync],
  );

  return {
    roles,
    isLoading: isPendingRoles || isPendingUsers,
    shareClient,
    clientUsers,
  };
};
