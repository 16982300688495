import { useEffect } from 'react';

import { useLatest } from './useLatest';
import { isDev } from './utils/isDev';

export const useUnmount = (fn: () => void) => {
  if (isDev && typeof fn !== 'function') {
    console.error(`useUnmount expected parameter is a function, got ${typeof fn}`);
  }

  const fnRef = useLatest(fn);

  useEffect(
    () => () => {
      fnRef.current();
    },
    [fnRef],
  );
};
