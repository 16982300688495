import type { DropdownItemProps, UseDisclosureProps } from '@heroui/react';
import type { FC, ReactNode } from 'react';
import { useCallback, useState } from 'react';

import {
  ArrowTopRightOnSquareIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/solid';
import { Card, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@heroui/react';
import { useTranslations } from 'next-intl';

import type { dice } from '@org/query';
import type { BaseTableProps } from '@org/ui';
import { hasPermission } from '@org/features';
import { Button, Modal, ModalContent, SearchField } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import {
  AddNewClientModalContent,
  DeleteClientModalContent,
  RenameClientModalContent,
  ShareClientModalContent,
} from './components';
import { useController } from './useController';

interface ClientsOverviewProps extends Omit<BaseTableProps, keyof ControllerType>, ControllerType {}

type ModalContentKeys = 'ADD_NEW_CLIENT' | 'RENAME_CLIENT' | 'DELETE_CLIENT' | 'SHARE_CLIENT';

interface ClientModalContentProps {
  updateClient: (client: dice.ClientDTO) => Promise<dice.ClientDTO[]>;
  createClient: (clientName: string) => Promise<dice.ClientDTO[]>;
  deleteClient: (clientId: string) => Promise<void>;
  disclosureState: UseDisclosureProps;
  selectedClient: dice.ClientDTO;
  sortedClients?: dice.ClientRoleDTO[];
}

interface DropDownItem {
  icon: ReactNode;
  onClick: (client: dice.ClientDTO) => void;
  label: string;
  className: string;
  color: DropdownItemProps['color'];
  key: string;
}

const View: FC<ClientsOverviewProps> = ({
  LinkComponent,
  getLinkProps,
  sortedClients,
  disclosureState,
  createClient,
  updateClient,
  deleteClient,
  handleSearch,
  control,
  user,
}) => {
  const t = useTranslations();
  const [selectedClient, setSelectedClient] = useState<dice.ClientDTO>({});
  const [activeModalKey, setActiveModalKey] = useState<ModalContentKeys>('ADD_NEW_CLIENT');

  const { onOpen, onOpenChange, isOpen } = disclosureState;

  const MODAL_CONTENT_LIST: Record<ModalContentKeys, FC<ClientModalContentProps>> = {
    ADD_NEW_CLIENT: AddNewClientModalContent,
    DELETE_CLIENT: DeleteClientModalContent,
    RENAME_CLIENT: RenameClientModalContent,
    SHARE_CLIENT: ShareClientModalContent,
  };

  const SelectedModalContent = MODAL_CONTENT_LIST[activeModalKey];

  const clientModalContentProps = {
    createClient,
    deleteClient,
    disclosureState,
    selectedClient,
    sortedClients,
    updateClient,
  };

  const handleOnDropdownItemClick = useCallback(
    (key: ModalContentKeys, client: dice.ClientDTO) => {
      onOpen();
      setActiveModalKey(key);
      setSelectedClient(client);
    },
    [onOpen],
  );

  const generateDropDownItems = useCallback(
    (clientAndRole: dice.ClientRoleDTO) => {
      const menuItems = [];

      if (hasPermission({ user, resource: 'clients', action: 'edit', data: clientAndRole })) {
        menuItems.push({
          label: t('common.contextMenu.edit'),
          key: 'RENAME_CLIENT',
          icon: (
            <PencilSquareIcon
              height={14}
              width={14}
            />
          ),
          onClick: (client: dice.ClientDTO) => handleOnDropdownItemClick('RENAME_CLIENT', client),
          className: 'text-black',
          color: 'default',
        });
      }

      if (hasPermission({ user, resource: 'clients', action: 'share', data: clientAndRole })) {
        menuItems.push({
          label: t('common.contextMenu.share'),
          key: 'SHARE_CLIENT',
          icon: (
            <ArrowTopRightOnSquareIcon
              height={14}
              width={14}
            />
          ),
          onClick: (client: dice.ClientDTO) => handleOnDropdownItemClick('SHARE_CLIENT', client),
          className: 'text-black',
          color: 'default',
        });
      }

      if (hasPermission({ user, resource: 'clients', action: 'delete', data: clientAndRole })) {
        menuItems.push({
          label: t('common.contextMenu.delete'),
          key: 'DELETE_CLIENT',
          icon: (
            <TrashIcon
              height={14}
              width={14}
            />
          ),
          onClick: (client: dice.ClientDTO) => handleOnDropdownItemClick('DELETE_CLIENT', client),
          className: 'text-red-500',
          color: 'danger',
        });
      }

      return menuItems as DropDownItem[];
    },
    [t, user, handleOnDropdownItemClick],
  );

  return (
    <>
      <div className="h-full">
        <div className="mx-auto my-4 flex max-w-7xl justify-center space-x-8 px-8">
          {hasPermission({ user, resource: 'clients', action: 'create' }) && (
            <Button
              className="flex cursor-pointer items-center !rounded-md"
              onPress={() => {
                onOpen();
                setActiveModalKey('ADD_NEW_CLIENT');
              }}
              size="md"
              variant="secondary"
            >
              <PlusIcon className="size-4" />
              <div>{t('main.clients.clientsOverviewPanel.header')}</div>
            </Button>
          )}

          <SearchField
            className="w-3/6"
            control={control}
            name="search"
            onChange={handleSearch}
          />
        </div>
        <div className="mx-auto max-w-7xl px-1 py-2 pb-5">
          <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3 xl:grid-cols-4">
            {sortedClients.map((clientAndRole) => {
              const client = clientAndRole.client!;
              const { clientName = '', id } = client;

              const dropDownItems = generateDropDownItems(clientAndRole);
              return (
                <Card
                  as={LinkComponent}
                  className="h-[100px] rounded-md p-4 hover:bg-actions-hover hover:text-black hover:no-underline md:max-w-[340px]"
                  key={id}
                  {...getLinkProps(`${id}`)}
                >
                  <div className="flex justify-between space-x-4">
                    <div className="break-words">{clientName}</div>

                    {dropDownItems.length > 0 && (
                      <div className="">
                        <Dropdown>
                          <DropdownTrigger className="size-8 rounded p-1 hover:bg-gray-200">
                            <EllipsisVerticalIcon
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Static Actions"
                            items={dropDownItems}
                          >
                            {(item) => (
                              <DropdownItem
                                className={item.className}
                                color={item.color}
                                key={item.key}
                                onPress={() => item.onClick(client)}
                                startContent={item.icon}
                              >
                                {item.label}
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    )}
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        size="5xl"
      >
        <ModalContent>
          <SelectedModalContent {...clientModalContentProps} />
        </ModalContent>
      </Modal>
    </>
  );
};

export const ClientsOverview = bindControllers(View, [useController]);

ClientsOverview.displayName = 'ClientsOverview';
