import type { GridApi } from '@ag-grid-community/core';

/**
 * This function is deprecated.
 */
export const getCopyOfTableData = <T>(gridApi: GridApi<T> | undefined) => {
  // const rowData = gridApi?.getGridOption('rowData') as T[];

  const data: unknown[] = [];
  gridApi?.forEachLeafNode((rowNode) => data.push({ ...rowNode.data }));
  return data as T[];
};
