import type { FC } from 'react';

import type { BaseTableProps } from '@org/ui';
import { BaseTable } from '@org/ui';
import { bindControllers } from '@org/utils';

import type { ControllerType } from './useController';
import { useController } from './useController';

interface ShareWithUsersTableProps
  extends Omit<BaseTableProps, keyof ControllerType>,
    ControllerType {}

const rowSelection = {
  mode: 'multiRow',
  checkboxes: true,
  headerCheckbox: true,
  enableClickSelection: false,
  groupSelects: 'filteredDescendants',
} as const;

const ShareWithUsersTableView: FC<ShareWithUsersTableProps> = (props) => {
  const {
    columnDefs,
    modal,
    agGridProps,
    defaultColDef,
    getRowId,
    onGridReady,
    isLoading,
    onCellValueChange,
  } = props;

  return (
    <div className="flex h-full flex-col gap-3 pt-3">
      <div className="flex-1">
        <BaseTable
          {...agGridProps}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          loading={isLoading}
          onCellValueChanged={onCellValueChange}
          onGridReady={onGridReady}
          rowSelection={rowSelection}
        />
      </div>
      {modal}
    </div>
  );
};

export const ShareWithUsersTable = bindControllers(ShareWithUsersTableView, [useController]);
ShareWithUsersTable.displayName = 'ShareWithUsersTable';
