import type { ColDef, ColGroupDef } from '@ag-grid-community/core';
import { useMemo } from 'react';

import { TrashIcon } from '@heroicons/react/24/outline';
import { useTranslations } from 'next-intl';

import type { dice } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';

export interface UseColumnsProps {
  handleDeleteModal: (props: ButtonCellRendererProps) => unknown;
  rolesOptions: { value: string; label: string }[];
}

export const useColumns = ({ handleDeleteModal, rolesOptions }: UseColumnsProps) => {
  const t = useTranslations();

  return useMemo(
    (): (ColDef<dice.UserRoleMapTO> | ColGroupDef<dice.UserRoleMapTO>)[] => [
      {
        colId: 'email',
        field: 'email',
        flex: 2,
        headerName: t('common.contextMenu.modalShare.tableColumns.email'),
      },
      {
        colId: 'firstName',
        field: 'firstName',
        flex: 1,
        headerName: t('common.contextMenu.modalShare.tableColumns.firstName'),
      },
      {
        colId: 'lastName',
        field: 'lastName',
        flex: 1,
        headerName: t('common.contextMenu.modalShare.tableColumns.lastName'),
      },
      {
        colId: 'role',
        field: 'role',
        headerName: t('common.contextMenu.modalShare.tableColumns.role'),
        type: 'select',
        cellEditorParams: {
          options: rolesOptions,
          placeholder: t('common.contextMenu.modalShare.tableColumns.role'),
        },
        editable: true,
        valueSetter: (params) => {
          let selectedNodes = params.api.getSelectedNodes();
          if (selectedNodes.length === 0 && params.node) {
            selectedNodes = [params.node];
          }

          selectedNodes.forEach((node) => {
            node.setData({
              ...node.data,
              role: params.newValue,
            });
          });

          params.node?.setData({
            ...params.node.data,
            role: params.newValue,
          });

          return true;
        },
      },
      {
        cellRenderer: 'multipleButtons',
        cellRendererParams: {
          buttonParams: [
            {
              icon: (
                <TrashIcon
                  height={20}
                  width={20}
                />
              ),
              onClick: (props: ButtonCellRendererProps) => handleDeleteModal(props),
              size: 'iconButton',
            },
          ],
          className: 'flex mr-[-4px]',
        },
        colId: 'actions',
        type: 'actions',
        filter: null,
        headerName: t('common.actions'),
        maxWidth: 60,
      },
    ],
    [handleDeleteModal, rolesOptions, t],
  );
};
