import type { UseDisclosureProps } from '@heroui/react';
import { useState } from 'react';

import { Input } from '@heroui/react';
import { useTranslations } from 'next-intl';

import type { dice } from '@org/query';
import { useKeys } from '@org/hooks';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import { checkStringForNotAllowedChars, getDuplicatedClientName } from '../utils';

interface AddNewClientModalContentProps {
  disclosureState: UseDisclosureProps;
  createClient: (clientName: string) => Promise<dice.ClientDTO[]>;
  sortedClients?: dice.ClientRoleDTO[];
}

export const AddNewClientModalContent = ({
  disclosureState,
  createClient,
  sortedClients,
}: AddNewClientModalContentProps) => {
  const t = useTranslations();

  const [newClientName, setNewClientName] = useState('');

  const { isOpen, onClose } = disclosureState;

  const handleCloseModal = () => {
    onClose?.();
    setNewClientName('');
  };

  const clients = sortedClients?.map((clientRole) => clientRole.client!) ?? [];

  const duplicatedClient = getDuplicatedClientName(newClientName, clients);

  useKeys('Enter', () => {
    const isValid = isOpen && newClientName && !duplicatedClient;

    if (isValid) {
      void createNewClient();
    }
  });

  const createNewClient = async () => {
    if (!newClientName) {
      return;
    }

    if (duplicatedClient) {
      const notificationConfig = {
        clientName: newClientName,
        sharingUsersIdList: duplicatedClient.sharingUsersIdList?.join(', ') ?? t('common.nobody'),
        userId: duplicatedClient.userId,
      };
      showNotification({
        status: 'warning',
        message: t(
          'main.clients.clientsOverviewPanel.alerts.warning.duplicatedClient',
          notificationConfig,
        ),
        autoClose: 15_000,
      });
      handleCloseModal();

      return;
    }

    try {
      await createClient(newClientName);
      handleCloseModal();
    } catch {
      showNotification({
        status: 'warning',
        message: t('main.clients.clientsOverviewPanel.alerts.warning.general'),
      });
    }
  };

  return (
    <>
      <ModalHeader>{t('main.clients.clientsOverviewPanel.header')}</ModalHeader>
      <ModalBody>
        <Input
          label={t('main.clients.clientsOverviewPanel.lblName')}
          onChange={({ target: { value } }) => {
            const notValidChar = checkStringForNotAllowedChars(value);

            if (!notValidChar) {
              setNewClientName(value);
              return;
            }

            showNotification({
              status: 'warning',
              message: t('common.alerts.warning.notSupportedChar', {
                char: notValidChar,
              }),
            });
          }}
          value={newClientName}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          color="danger"
          onPress={handleCloseModal}
          size="md"
          variant="secondary"
        >
          {t('common.close')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={!!duplicatedClient || !newClientName}
          onPress={createNewClient}
          size="md"
          variant="primary"
        >
          {t('common.create')}
        </Button>
      </ModalFooter>
    </>
  );
};
