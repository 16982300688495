import type { ModalProps } from '@heroui/react';
import type { FC } from 'react';

import { useTranslations } from 'next-intl';

import { Button } from '../../Button';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '../../Modal';

export interface BaseConfirmationModalProps extends Omit<ModalProps, 'children'> {
  onConfirm?: () => unknown;
  onCancel?: () => void;
  contentText?: string;
  titleText?: string | React.ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  disabledConfirmButton?: boolean;
  disabledCancelButton?: boolean;
}

export const BaseConfirmationModal: FC<BaseConfirmationModalProps> = (props) => {
  const {
    titleText,
    contentText,
    confirmButtonText,
    cancelButtonText,
    disabledConfirmButton = false,
    disabledCancelButton = false,
    onCancel,
    onConfirm,
    isOpen,
    onOpenChange,
  } = props;

  const t = useTranslations();

  return (
    <div data-testid="BaseConfirmationModal">
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>{titleText}</ModalHeader>
              <ModalBody>{contentText}</ModalBody>
              <ModalFooter>
                <Button
                  disabled={disabledCancelButton}
                  onPress={() => {
                    onCancel?.();
                    onClose();
                  }}
                  variant="secondary"
                >
                  {cancelButtonText ?? t('common.btnCancel')}
                </Button>
                <Button
                  disabled={disabledConfirmButton}
                  onPress={() => {
                    onConfirm?.();
                    onClose();
                  }}
                  variant="primary"
                >
                  {confirmButtonText ?? t('common.ok')}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};
