import type { UseDisclosureProps } from '@heroui/react';
import { useState } from 'react';

import { Input } from '@heroui/react';
import { useTranslations } from 'next-intl';

import type { dice } from '@org/query';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import { checkStringForNotAllowedChars, getDuplicatedClientName } from '../utils';

interface RenameClientModalContentProps {
  disclosureState: UseDisclosureProps;
  updateClient: (client: dice.ClientDTO) => Promise<dice.ClientDTO[]>;
  selectedClient: dice.ClientDTO;
  sortedClients?: dice.ClientRoleDTO[];
}

export const RenameClientModalContent = ({
  disclosureState,
  selectedClient,
  updateClient,
  sortedClients,
}: RenameClientModalContentProps) => {
  const t = useTranslations();
  const { clientName } = selectedClient;
  const { onClose } = disclosureState;

  const [newClientName, setNewClientName] = useState(clientName ?? '');

  const clients = sortedClients?.map((clientRole) => clientRole.client!) ?? [];

  const duplicatedClient = getDuplicatedClientName(newClientName, clients);

  const handleCloseModal = () => {
    onClose?.();
    setNewClientName('');
  };

  return (
    <>
      <ModalHeader>
        {t('common.contextMenu.modalUpdate.title', {
          name: clientName,
          object: '',
        })}
      </ModalHeader>
      <ModalBody>
        <Input
          label={t('main.clients.clientsOverviewPanel.lblName')}
          onChange={({ target: { value } }) => {
            const notValidChar = checkStringForNotAllowedChars(value);

            if (!notValidChar) {
              setNewClientName(value);
              return;
            }

            showNotification({
              status: 'warning',
              message: t('common.alerts.warning.notSupportedChar', {
                char: notValidChar,
              }),
            });
          }}
          value={newClientName}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          onPress={handleCloseModal}
          size="md"
          variant="secondary"
        >
          {t('common.btnCancel')}
        </Button>
        <Button
          className="!rounded-md"
          isDisabled={!!duplicatedClient || !newClientName}
          onPress={async () => {
            try {
              await updateClient({ ...selectedClient, clientName: newClientName });
              handleCloseModal();
            } catch {
              showNotification({
                status: 'warning',
                message: t('main.clients.clientsOverviewPanel.alerts.warning.general'),
              });
            }
          }}
          size="md"
          variant="primary"
        >
          {t('common.update')}
        </Button>
      </ModalFooter>
    </>
  );
};
