import { useEffect, useMemo } from 'react';

import debounce from 'lodash/debounce';

import type { CorrectAny } from '@org/types';

import type { DebounceOptions } from './useDebounce';
import { useLatest } from './useLatest';
import { useUnmount } from './useUnmount';
import { isDev } from './utils/isDev';

type Noop = (...args: any[]) => CorrectAny;

export function useDebounceFn<T extends Noop>(fn: T, options: DebounceOptions = {}) {
  if (isDev && typeof fn !== 'function') {
    console.error(`useDebounceFn expected parameter is a function, got ${typeof fn}`);
  }
  const { wait = 1000, signal } = options;

  const fnRef = useLatest(fn);

  const debounced = useMemo(
    () =>
      debounce((...args: Parameters<T>): ReturnType<T> => fnRef.current(...args), wait, options),
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const handler = () => {
      debounced.flush();
    };
    signal?.addEventListener('abort', handler);
    return () => signal?.removeEventListener('abort', handler);
  }, [debounced, signal]);

  useUnmount(() => {
    if (signal?.aborted === true) {
      return;
    }
    debounced.cancel();
  });

  return debounced;
}
