import type { CellValueChangedEvent, ColDef, GetRowIdFunc } from '@ag-grid-community/core';
import type { AgGridReact } from '@ag-grid-community/react';
import { useCallback, useRef } from 'react';

import { useTranslations } from 'next-intl';

import type { dice } from '@org/query';
import type { ButtonCellRendererProps } from '@org/ui';
import { useAgGridData } from '@org/hooks';
import { aggregated } from '@org/query';
import { showNotification, useDeleteRowConfirmModal } from '@org/ui';
import { getCopyOfTableData } from '@org/utils';

import { useColumns } from './useColumns';

interface UseControllerProps {
  users: dice.UserRoleMapTO[];
  clientId: string;
  rolesOptions: { value: string; label: string }[];
}
const getRowId: GetRowIdFunc<dice.UserRoleMapTO> = ({ data }) => data.email ?? '';

const defaultColDef: ColDef = {
  filter: 'agTextColumnFilter',
  floatingFilter: true,
};

export const useController = ({ users, clientId, rolesOptions }: UseControllerProps) => {
  const tableRef = useRef<AgGridReact>(null);
  const t = useTranslations();

  const { shareClient } = aggregated.useClientShare({});

  const agGridProps = useAgGridData({
    agGridRef: tableRef,
    data: users,
  });

  const deleteRow = useCallback(
    async ({ api, data }: ButtonCellRendererProps) => {
      const tableData = getCopyOfTableData(api);

      let restOfUsers = [];

      const selectedNodes = api.getSelectedNodes();

      if (selectedNodes.length > 0) {
        const selectedEmails = new Set(selectedNodes.map((node) => node.data.email));
        restOfUsers = tableData.filter((user) => !selectedEmails.has(user.email));
      } else {
        const deletedEmail = data.email;
        restOfUsers = tableData.filter((user) => user.email !== deletedEmail);
      }

      try {
        await shareClient({ toClientId: clientId, toUsers: restOfUsers });

        showNotification({
          status: 'success',
          message: t('main.clients.clientsOverviewPanel.alerts.success.shareRoleDeleted'),
        });
      } catch {
        showNotification({ status: 'error', message: t('common.alerts.error.generalWithMsg') });
      }
    },
    [clientId, shareClient, t],
  );

  const { modal, openModal: handleDeleteModal } = useDeleteRowConfirmModal<ButtonCellRendererProps>(
    {
      onConfirm: deleteRow,
    },
  );

  const columnDefs = useColumns({
    handleDeleteModal,
    rolesOptions,
  });

  const onCellValueChange = useCallback(
    async ({ colDef, api, data, newValue }: CellValueChangedEvent) => {
      const colName = colDef.field;

      if (!colName) {
        return;
      }

      const tableData = getCopyOfTableData(api);

      let updatedUsers = tableData;

      if (api.getSelectedNodes().length === 0) {
        updatedUsers = tableData.map((user) => {
          if (user.email === data.email) {
            user.role = newValue;
          }
          return user;
        });
      }

      try {
        await shareClient({ toClientId: clientId, toUsers: updatedUsers });

        showNotification({
          status: 'success',
          message: t('main.clients.clientsOverviewPanel.alerts.success.shareRoleUpdated'),
        });
      } catch {
        showNotification({ status: 'error', message: t('common.alerts.error.generalWithMsg') });
      }
    },
    [shareClient, clientId, t],
  );

  return {
    columnDefs,
    defaultColDef,
    getRowId,
    modal,
    agGridProps,
    isLoading: false,
    onCellValueChange,
  };
};

export type ControllerType = ReturnType<typeof useController>;
