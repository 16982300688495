import type { UseDisclosureProps } from '@heroui/react';
import { useCallback, useMemo, useState } from 'react';

import { useTranslations } from 'next-intl';

import type { dice } from '@org/query';
import { Combobox, ComboboxItem } from '@org/design';
import { useUserPermission } from '@org/features';
import { aggregated } from '@org/query';
import { Button, ModalBody, ModalFooter, ModalHeader, showNotification } from '@org/ui';

import { ShareWithUsersTable } from './ShareWithUsersTable';

interface ShareClientModalContentNewProps {
  disclosureState: UseDisclosureProps;
  selectedClient: dice.ClientDTO;
}

export const ShareClientModalContent = ({
  disclosureState,
  selectedClient,
}: ShareClientModalContentNewProps) => {
  const t = useTranslations();
  const { clientName = '', id: clientId } = selectedClient;
  const { user } = useUserPermission({ clientId });

  const { onClose } = disclosureState;
  const { roles, shareClient, clientUsers } = aggregated.useClientShare({
    clientId,
  });

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const [selectedRole, setSelectedRole] = useState('');

  const usersToShareWith = clientUsers?.filter(({ email }) => email !== user.email) ?? [];

  const sharingUsersList = useMemo(
    () => clientUsers?.filter(({ role }) => Boolean(role)) ?? [],
    [clientUsers],
  );

  const options = usersToShareWith.map(({ email }) => ({
    label: email ?? '',
    value: email ?? '',
  }));

  const rolesOptions = useMemo(
    () =>
      roles?.map((role) => ({
        label: role,
        value: role,
      })) ?? [],
    [roles],
  );

  const handleShareClient = useCallback(async () => {
    if (!selectedRole || selectedUsers.length === 0) {
      showNotification({
        status: 'warning',
        message: t('common.contextMenu.modalShare.errors.userAndRoleRequired'),
      });
      return;
    }

    const newUsers = selectedUsers.map((selectedUserEmail) => ({
      email: selectedUserEmail,
      role: selectedRole,
    })) as dice.ShareClientNewRequestBody;

    const toUsers = [
      ...newUsers,
      ...sharingUsersList.filter((item1) => !newUsers.some((item2) => item2.email === item1.email)),
    ];

    try {
      await shareClient({ toClientId: clientId ?? '', toUsers });
      setSelectedRole('');
      setSelectedUsers([]);
      showNotification({
        status: 'success',
        message: t('main.clients.clientsOverviewPanel.alerts.success.share', {
          clientName,
        }),
      });
    } catch {
      showNotification({
        status: 'warning',
        message: t('main.clients.clientsOverviewPanel.alerts.warning.general'),
      });
    }
  }, [clientId, clientName, selectedRole, selectedUsers, shareClient, sharingUsersList, t]);

  return (
    <>
      <ModalHeader>
        {t('common.contextMenu.modalShare.title', {
          client: clientName,
        })}
      </ModalHeader>
      <ModalBody>
        {t('common.contextMenu.modalShare.labelAddUser')}
        <div className="flex w-full gap-x-4">
          <Combobox
            aria-label="select users to share with"
            className="mb-2 w-[65dvw]"
            isMultiple
            onSelectionChange={(newValue) => {
              setSelectedUsers([...newValue] as string[]);
            }}
            placeholder={t('common.contextMenu.modalShare.placeholderUsername')}
            selectedKeys={selectedUsers}
          >
            {options.map(({ label, value }) => (
              <ComboboxItem key={value}>{label}</ComboboxItem>
            ))}
          </Combobox>

          <Combobox
            aria-label="select users to share with"
            className="mb-2 w-[35dvw]"
            onSelectionChange={(newValue) => {
              setSelectedRole(newValue as string);
            }}
            placeholder={t('common.contextMenu.modalShare.placeholderRole')}
            selectedKey={selectedRole}
          >
            {rolesOptions.map(({ label, value }) => (
              <ComboboxItem key={value}>{label}</ComboboxItem>
            ))}
          </Combobox>
        </div>

        <div className="flex w-full justify-end">
          <Button
            className="!rounded-md"
            isDisabled={!clientName}
            onPress={async () => {
              await handleShareClient();
            }}
            size="md"
            variant="primary"
          >
            {t('common.update')}
          </Button>
        </div>

        <section>
          <div className="mb-2 mt-4">
            {sharingUsersList.length > 0
              ? t('common.contextMenu.modalShare.labelClientOwners')
              : t('common.contextMenu.modalShare.labelNotShared')}
          </div>

          <div className="h-[400px] py-2">
            <ShareWithUsersTable
              clientId={clientId!}
              rolesOptions={rolesOptions}
              users={sharingUsersList}
            />
          </div>
        </section>
      </ModalBody>
      <ModalFooter>
        <Button
          className="!rounded-md"
          onPress={onClose}
          size="md"
          variant="secondary"
        >
          {t('common.close')}
        </Button>
      </ModalFooter>
    </>
  );
};
