import { useRef } from 'react';

export function useLatest<T>(value: T) {
  const ref = useRef(value);

  // eslint-disable-next-line react-compiler/react-compiler
  ref.current = value;

  return ref;
}
